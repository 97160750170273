import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import pdf from "../../Assets/CV-Pham-Duc-Dat.pdf";
import mmms2024 from "../../Assets/MMMS 2024 - EVALLOS.pptx"
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Container fluid className="resume-section">
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank" className="download-button">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>

          {/* <Button variant="primary" href={mmms2024} target="_blank" className="download-button">
            <AiOutlineDownload />
            &nbsp;Download MMMS 2024 Slide
          </Button> */}
        </Row>
        <Row className="resume">
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: 2 }, (_, index) => index + 1).map((pageNumber) => (
              <Page key={pageNumber} pageNumber={pageNumber} scale={width > 786 ? 1.7 : 0.6} />
            ))}
          </Document>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
